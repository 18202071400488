<template>
  <div>
    <ComHeader menuKey="plan" :topY="1"></ComHeader>
    <img class="menu-box" src="@/assets/images/menu-bg.png" alt="" />
    <!-- main -->
    <div class="container plan-detail animate__animated animate__slideInLeft">
      <div class="plan-item">
        <div class="plan-item-title">{{ planShow.title }}</div>
        <p class="indent-2">{{ planShow.info }}</p>
        <div class="info-wrap" v-if="planShow.infoItem">
          <div
            class="info-wrap-item"
            v-for="(item, index) in planShow.infoItem"
            :key="index"
          >
            <span class="title">{{ item.tip }}</span>
            <span v-if="item.tip">:</span>
            <span>{{ item.txt }}</span>
          </div>
        </div>
      </div>
      <div class="plan-item">
        <div class="plan-item-title">行业痛点</div>
        <div class="pain-wrap">
          <div
            class="pain-item"
            v-for="(item, index) in planShow.pain"
            :key="index"
          >
            <div class="pain-item-title">
              <img :src="item.imgUrl" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.txt }}</div>
          </div>
        </div>
      </div>
      <div class="plan-item">
        <div class="plan-item-title">方案优势</div>
        <div class="advan-wrap">
          <div
            class="advan-item"
            v-for="(item, index) in planShow.advan"
            :key="index"
          >
            <img :src="item.imgUrl" alt="" />
            <div>{{ item.txt }}</div>
          </div>
        </div>
      </div>
      <div class="plan-item" v-if="planShow.successList">
        <div class="plan-item-title">成功案例</div>
        <div
          class="success-img-wrap"
          v-for="(item, index) in planShow.successList"
          :key="index"
        >
          <div
            class="success-img-item"
            :class="'success-img-item-' + item.length"
            v-for="(child, ind) in item"
            :key="ind"
          >
            <img :src="child.imgUrl" alt="" />
            <p>{{ child.txt }}</p>
          </div>
        </div>
        <p class="success-tip" v-if="planShow.intro">{{ planShow.intro }}</p>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import ComHeader from "@/components/header.vue";
import ComFooter from "@/components/footer.vue";

let painIcon = [
  require("../../assets/images/plan/b-icon1.png"),
  require("../../assets/images/plan/b-icon2.png"),
  require("../../assets/images/plan/b-icon3.png"),
];

let advanIcon = [
  require("../../assets/images/plan/adv-icon1.png"),
  require("../../assets/images/plan/adv-icon2.png"),
  require("../../assets/images/plan/adv-icon3.png"),
  require("../../assets/images/plan/adv-icon4.png"),
];

export default defineComponent({
  components: { ComHeader, ComFooter },

  setup() {
    const route = useRoute();
    let id = route.query.id;

    let planList = [
      {
        id: "active",
        title: "重大活动",
        info: "实现展览、演唱会、发布会、论坛等大型活动的快捷通行。参与人可提前预约，核验通行，有效避免通行阻塞，同时支持疫情防控联动，实时同步防疫异常信息，并对关注人员实时预警，警企联动、快速布防，为大家健康安全提供有力保障。",
        pain: [
          {
            title: "通行慢",
            txt: "人工核验速度慢，导致高峰期人员通行受阻，容易造成安全隐患，且人员聚集会增加疫情风险",
            imgUrl: painIcon[0],
          },
          {
            title: "安全低",
            txt: "身份核验过程中会因为各种情况导致核验不准确，容易导致关注人员进入场地，影响现场安全",
            imgUrl: painIcon[1],
          },
          {
            title: "成本高",
            txt: "传统核验设备装配复杂，可自由操作难度大，且覆盖面低，项目前后期建设成本高",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "提前登记预约，无证核验，避免通行阻塞", imgUrl: advanIcon[0] },
          {
            txt: "疫情防控联动，实时同步异常信息，避免人员聚集感染",
            imgUrl: advanIcon[1],
          },
          {
            txt: "对关注人员实时预警，警企联动、快速布防",
            imgUrl: advanIcon[2],
          },
          {
            txt: "建设快，成本低，设备便携、精准度高，场景覆盖面广",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "成都糖酒会",
              imgUrl: require("../../assets/images/plan/active-img1.png"),
            },
            {
              txt: "成都国际车展",
              imgUrl: require("../../assets/images/plan/active-img2.png"),
            },
            {
              txt: "成都大运会",
              imgUrl: require("../../assets/images/plan/active-img3.png"),
            },
            {
              txt: "陕西全运会",
              imgUrl: require("../../assets/images/plan/active-img4.png"),
            },
          ],
          [
            {
              txt: "西部国际博览城",
              imgUrl: require("../../assets/images/plan/active-img5.png"),
            },
            {
              txt: "成都世纪新国际会展中心",
              imgUrl: require("../../assets/images/plan/active-img6.png"),
            },
          ],
        ],
        intro: "日检峰值达到10万人，中码提供设备租赁与服务保障共300余场",
      },
      {
        id: "hospital",
        title: "医院校园",
        infoItem: [
          {
            tip: "校园",
            txt: "校园入口部署访客管理系统，建立校园白名单，实时核验出入人员身份，记录出入信息，可疑行踪一目了然。异常人员实时预警，核验信息自动上传公安内网，精准信息比对，确保校园安全。",
          },
          {
            tip: "医院",
            txt: "特殊药品领取实时身份核验登记信息匹配国家数据源，及时反馈身份信息，保证真人真领，缩短核验流程，提高核验效率及真实性，如果紧急情况，及时反馈信息到警察，快速处理，保障医院及人员安全。",
          },
        ],
        pain: [
          {
            title: "无比对数据",
            txt: "无法精准核实关注人员信息，保证人员安全，对于紧急事态无法及时预警处理",
            imgUrl: painIcon[0],
          },
          {
            title: "安全度低",
            txt: "人工身份核验过程中会因为各种情况导致核验不准确，行踪追溯慢，信息遗失",
            imgUrl: painIcon[1],
          },
          {
            title: "核验流程长",
            txt: "传统核验流程冗长，登记耗时长",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "无证核验，自动预警", imgUrl: advanIcon[0] },
          { txt: "数据权威，比对精准", imgUrl: advanIcon[1] },
          { txt: "警企联动，快速处理", imgUrl: advanIcon[2] },
          { txt: "行踪可溯，确保安全", imgUrl: advanIcon[3] },
        ],
        successList: [
          [
            {
              txt: "某实验小学",
              imgUrl: require("../../assets/images/plan/hospital-img1.png"),
            },
            {
              txt: "国内某大型三家综合医院-特殊药品领取“实我风险核验认证”",
              imgUrl: require("../../assets/images/plan/hospital-img2.png"),
            },
          ],
        ],
      },
      {
        id: "agency",
        title: "智能代办",
        info: "目前很多业务允许他人代办，为了规范代办流程，可以通过中码身份证有效期核验业务，业务受理方通过我司的前端感知设备读取身份证信息，并通过网络核验此身份证的有效性，核验结果在前端感知设备得屏幕显示，同时提供打印身份信息凭条。",
        pain: [
          {
            title: "流程不安全",
            txt: "操作过程中无法快速核实代办人及办理人身份信息，容易产生安全漏洞",
            imgUrl: painIcon[0],
          },
          {
            title: "身份证滥用",
            txt: "无法识别代办人所持身份证的有效性，导致失效身份证被冒用，造成损失",
            imgUrl: painIcon[1],
          },
          {
            title: "核实手续多",
            txt: "传统代办需要核实的资料和手续冗长繁琐，容易造成办理人负面情绪",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "核验迅速，减少等待时间", imgUrl: advanIcon[0] },
          { txt: "避免信息被冒用，减少损失", imgUrl: advanIcon[1] },
          { txt: "确保流程安全可信，减少不合规操作", imgUrl: advanIcon[2] },
          { txt: "智能化操作办理，减少人工漏洞", imgUrl: advanIcon[3] },
        ],
        successList: [
          [
            {
              txt: "某车管所",
              imgUrl: require("../../assets/images/plan/agency-img1.png"),
            },
            {
              txt: "某4S店",
              imgUrl: require("../../assets/images/plan/agency-img2.png"),
            },
          ],
        ],
      },
      {
        id: "special",
        title: "特种行业",
        info: "对于特种行业，例如散装汽油销售、服务业、物流业、娱乐场所等，与人们生活息息相关，人员流动较大，容易产生危害社会公共安全的事情，对于这些行业的治安管理，更需要严格把控。",
        infoItem: [
          {
            tip: "散装汽油",
            txt: "人证合一，进行身份核验，确保购买人身份真实性，识别可疑人员，第一时间进行预警通知。",
          },
          {
            tip: "服务业",
            txt: "服务行业员工管理平台增设人证合一认证环节，预防不法分子进入服务行业，危害社会安全。",
          },
          {
            tip: "物流业",
            txt: "对寄件人的权威核验，提升服务效率，同时对配送员的身份核验，能避免不法分子伪装配送员，危害人民财产安全。",
          },
          {
            tip: "娱乐场所",
            txt: "对进出人员进行身份核实，预防不法分子进入到娱乐场所，同时做到配合公安查询核实，保证场所人员安全及社会治安管理。",
          },
        ],
        pain: [
          {
            title: "安全性低",
            txt: "场所鱼龙混杂，人员流动量大，管控难，人员安全及社会治安管理难以保证",
            imgUrl: painIcon[0],
          },
          {
            title: "管理不规范",
            txt: "没有规范的人员管理制度，容易造成不法分子进入，危害人民和社会安全",
            imgUrl: painIcon[1],
          },
          {
            title: "行踪难追溯",
            txt: "如遇紧急情况，警察无法判定人员信息，及时处理",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "核验权威，可疑情况，及时预警", imgUrl: advanIcon[0] },
          { txt: "预防犯罪，保护人民与社会安全", imgUrl: advanIcon[1] },
          { txt: "管理规范，提升服务效率", imgUrl: advanIcon[2] },
          { txt: "警企联动，行踪可溯，快速处理", imgUrl: advanIcon[3] },
        ],
        successList: [
          [
            {
              txt: "某加油站",
              imgUrl: require("../../assets/images/plan/special-img1.png"),
            },
            {
              txt: "某娱乐场所",
              imgUrl: require("../../assets/images/plan/special-img2.png"),
            },
          ],
        ],
      },
      {
        id: "online",
        title: "网络监管",
        info: "对于网络游戏、直播及互联网金融、社交电商等，引入身份核验系统，减少不良影响，维护网络安全，提升网络综合治理能力。",
        infoItem: [
          {
            txt: "● 引入智能的身份核验技术，对未成年人进行强监管，并为监护人履行起监护职责，提供时间管理、权限管理、消费管理等功能，从而合理限制未成年人在使用此类产品及服务的消费数额，完善网络游戏和网络直播的防沉迷制度。",
          },
          {
            txt: "● 通过可信身份核验技术的应用，有效判断直播从业人员的身份信息，快速完成对关注人员的响应，与公安联动，及时制止直播背后隐藏的电信诈骗等违法犯罪行为，保障公民的人身财产安全。",
          },
          {
            txt: "● 互联网身份核验可以最大限度的提升网络道德水平，构建纯净的网络环境。另一方面，能最大限度的减少利用互联网的各种违法犯罪行为，保护网络安全，同时，对于保护青少年起了重大作用。",
          },
          {
            txt: "● 互联网身份信息直接与国家权威数据比对，杜绝了敌特的渗透，对关注人员及时预警，做到对犯罪者追溯源头，同时，提高网民安全意识，保护网民生命财产。",
          },
        ],
        pain: [
          {
            title: "未成年人监管困难",
            txt: "未成年人对网络游戏、直播等无法有效自控，容易沉迷，监护人无法实时监督管控",
            imgUrl: painIcon[0],
          },
          {
            title: "隐藏的电信诈骗",
            txt: "普通民众无法有效的判断互联网背后隐藏的电信诈骗行为，容易造成财产损失",
            imgUrl: painIcon[1],
          },
          {
            title: "网络语言暴力",
            txt: "越来越多的网络语言暴力，影响着安全的网络环境和民众利益",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "未成年人强监督，共建健康网络环境", imgUrl: advanIcon[0] },
          { txt: "预防电信诈骗，保护民众财产安全", imgUrl: advanIcon[1] },
          { txt: "构建纯净网络环境，保护网络安全", imgUrl: advanIcon[2] },
          { txt: "杜绝敌特渗透，维护国家信息安全", imgUrl: advanIcon[3] },
        ],
      },
      {
        id: "epidemic",
        title: "疫情联防",
        info: "疫情防控，联防联控，对人员身份管理，通过身份证号反馈健康码信息，或是通过读取国康码信息，记录人员信息，对人员的核酸、疫苗、行程数据进行查验，保证疫情期间，各种场景下的人员管控核验。",
        pain: [
          {
            title: "安全性低",
            txt: "各场所疫情核查容易次感染",
            imgUrl: painIcon[0],
          },
          {
            title: "无一码通行",
            txt: "现有设备不支持一码核验核酸、疫苗、行程数据等信息，无法同步核验",
            imgUrl: painIcon[1],
          },
          {
            title: "系统预警难",
            txt: "如遇特殊情况，无法对疫情人员进行系统预警",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          { txt: "疫情防控，联防联控", imgUrl: advanIcon[0] },
          { txt: "一标三实，一码通行", imgUrl: advanIcon[1] },
          { txt: "身份核验，系统预警", imgUrl: advanIcon[2] },
          { txt: "减少聚集感染，提升人员管控能力", imgUrl: advanIcon[3] },
        ],
        successList: [
          [
            {
              txt: "唐山市政府防疫指挥部",
              imgUrl: require("../../assets/images/plan/epidemic-img1.png"),
            },
          ],
        ],
      },
      {
        id: "protection",
        title: "智慧内保",
        info: "以大数据、人工智能等前沿技术为依托，通过智能化安保体系，实现内保工作治安要素可防、可控、可查，推动内保工作方式由事后处置向事前预警转型升级，构建覆盖重点单位和行业系统的单位内部智慧化治安防控格局。可以应用于医院内保、学校内保、银行安全防控、党政机关及各企事业单位、涉密部门监管、单位保卫队伍监管等。",
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "传统内保方式难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "不闭环",
            txt: "数据不同步，尚未实现内保工作的全闭环",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实现了与公安实时联动，提供事前风险预警",
            imgUrl: advanIcon[1],
          },
          {
            txt: "实现了抓拍、研判、预警、处置全流程的“闭环”，消除后顾之忧",
            imgUrl: advanIcon[2],
          },
          {
            txt: "具备无证核验功能，便捷的同时也保障了安全",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "成都市政府第一办公区",
              imgUrl: require("../../assets/images/plan/protection-img1.png"),
            },
            {
              txt: "富士康科技集团",
              imgUrl: require("../../assets/images/plan/protection-img2.png"),
            },
            {
              txt: "北京瑞安宾馆",
              imgUrl: require("../../assets/images/plan/protection-img3.png"),
            },
          ],
          [
            {
              txt: "北京比特威尔有限公司",
              imgUrl: require("../../assets/images/plan/protection-img4.png"),
            },
            {
              txt: "四川九洲控股集团",
              imgUrl: require("../../assets/images/plan/protection-img5.png"),
            },
            {
              txt: "一汽大众",
              imgUrl: require("../../assets/images/plan/protection-img6.png"),
            },
            {
              txt: "京东方光电科技",
              imgUrl: require("../../assets/images/plan/protection-img7.png"),
            },
          ],
          [
            {
              txt: "检察院",
              imgUrl: require("../../assets/images/plan/protection-img8.png"),
            },
            {
              txt: "法院",
              imgUrl: require("../../assets/images/plan/protection-img9.png"),
            },
            {
              txt: "监狱",
              imgUrl: require("../../assets/images/plan/protection-img10.png"),
            },
            {
              txt: "看守所",
              imgUrl: require("../../assets/images/plan/protection-img11.png"),
            },
          ],
        ],
      },
      {
        id: "community",
        title: "智慧社区",
        info: "应用人脸识别、可信身份核验等前沿技术，并结合一系列硬件设施，构建出一体化解决方案，加强对访客监控管理，做到主动预警和有效拦截，提升业主满意度及工作效率，同时也能支持第三方ERP、访客系统的直接对接，实现社区的智慧化管理，让居民生活更加安全、便捷。",
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "传统社区安保方式难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "不便捷",
            txt: "传统人工管理方式难以满足居民多样的生活需求",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实现了与公安实时联动，保障居民的人身财产安全",
            imgUrl: advanIcon[1],
          },
          {
            txt: "现代化、智能化、人性化的设施，让居民在社区的生活更加安全、便利与舒心",
            imgUrl: advanIcon[2],
          },
          {
            txt: "减轻社区管理者及物业管理者的工作压力，实现高效、便捷的社区治理",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "成都超有范儿科技有限公司-社区API服务",
              imgUrl: require("../../assets/images/plan/community-img1.png"),
            },
          ],
        ],
      },
      {
        id: "building",
        title: "智慧楼宇",
        info: "针对写字楼、企业园区、大型卖场、图书馆等特定公共场所，应用可信身份核验等前沿技术，并结合智能摄像机、门禁等硬件设施，部署身份信息核验系统，为客户单位提供日常访客身份核查，受限人员匹配报警、联动报警、嫌疑人/高危人员进入时，实时发送预警信息及智能风险评估实现此类公共场所的智慧安防及智能管理。",
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "传统人工安保方式难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "不便捷",
            txt: "传统人工管理方式已难以满足用户对此类公共场所的功能化需求",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实现了与公安实时联动，保障公共场所的安全",
            imgUrl: advanIcon[1],
          },
          {
            txt: "现代化、智能化、人性化的设施，让用户更加安全、便利",
            imgUrl: advanIcon[2],
          },
          {
            txt: "有效降低人工成本，实现高效、便捷的楼宇治理",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "浙江电力集团",
              imgUrl: require("../../assets/images/plan/building-img1.png"),
            },
            {
              txt: "全友家私有限公司",
              imgUrl: require("../../assets/images/plan/building-img2.png"),
            },
          ],
        ],
      },
      {
        id: "hotel",
        title: "智慧酒店",
        info: "针对酒店、民宿等住宿经营场所，应用可信身份核验等前沿技术，并结合身份核验设备和应用软件，实现智慧安防和快捷入住，规范整个入住管理流程，有效保障经营者和客人的人身财产安全，又能节省人力资源，并避免入住高峰期客人等待时间过久，体验差的问题。",
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "传统人工安保方式难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "不便捷",
            txt: "传统人工登记方式速度慢，高峰期易造成拥堵",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实现了与公安实时联动，保障住宿经营场所的安全",
            imgUrl: advanIcon[1],
          },
          {
            txt: "具备无证核验功能，让客人拥有更佳的入住体验",
            imgUrl: advanIcon[2],
          },
          {
            txt: "有效降低人工成本，实现高效、便捷的酒店管理",
            imgUrl: advanIcon[3],
          },
        ],
      },
      {
        id: "traffic",
        title: "智慧交通",
        info: "应用可信身份核验和自助身份认证等技术，高效保障大中型城市公共交通枢纽的安全，并为旅客提供便捷的身份自证服务，让公共交通拥有更好的体验。",
        infoItem: [
          {
            tip: "乘车身份核验",
            txt: "通过智能的身份核验手段可实现精准的客流信息比对，并与公安达成联防机制，保证社会安全。",
          },
          {
            tip: "自助证明一体机",
            txt: "在机场、高铁、客运等场所，可以建设自助开具证明场景和自助证明一体机，便于处理各种特殊紧急情况。",
          },
        ],
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "传统人工安保方式难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "不友好",
            txt: "传统身份自证的方式往往让旅客难以实现",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实现了与公安实时联动，有效保障大中型公共交通枢纽的安全",
            imgUrl: advanIcon[1],
          },
          {
            txt: "实现身份的快捷自证，让旅客拥有更佳的体验",
            imgUrl: advanIcon[2],
          },
          {
            txt: "应用多种身份核验方式，让公共交通通勤效率更高，避免拥堵",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "成都轨道交通集团公司，使用APP注册认证服务200万余次",
              imgUrl: require("../../assets/images/plan/traffic-img1.png"),
            },
            {
              txt: "成都东客运汽车站-自助一体机",
              imgUrl: require("../../assets/images/plan/traffic-img2.png"),
            },
          ],
        ],
      },
      {
        id: "insurance",
        title: "金融保险",
        info: "应用于银行、金融、保险等行业，通过可信身份核验技术和智能硬件，严格核查人员身份信息，打击各种金融违法犯罪行为和反电信网络诈骗，维护社会健康发展。",
        pain: [
          {
            title: "不可信",
            txt: "数据来源碎片化且不权威，缺少信任度，有潜在风险",
            imgUrl: painIcon[0],
          },
          {
            title: "无预警",
            txt: "业务受理方难以对风险进行预警",
            imgUrl: painIcon[1],
          },
          {
            title: "高风险",
            txt: "目前电信网络诈骗的方式多样，业务受理方防不胜防",
            imgUrl: painIcon[2],
          },
        ],
        advan: [
          {
            txt: "权威数据来源，实现了可信身份核验，数据可控可查",
            imgUrl: advanIcon[0],
          },
          {
            txt: "关注人员数据实时同步，提供事前风险预警",
            imgUrl: advanIcon[1],
          },
          {
            txt: "实时联动公安，防范信贷诈骗和电信网络诈骗等风险",
            imgUrl: advanIcon[2],
          },
          {
            txt: "提供证件有效性核验，有效保障业务受理方的利益",
            imgUrl: advanIcon[3],
          },
        ],
        successList: [
          [
            {
              txt: "北京币码公司与用友网络：E函证+电子会计档案及未来的数字名片",
              imgUrl: require("../../assets/images/plan/insurance-img1.png"),
            },
            {
              txt: "成都交子金控集团：交子信用",
              imgUrl: require("../../assets/images/plan/insurance-img2.png"),
            },
          ],
          [
            {
              txt: "四川锦程消费金融",
              imgUrl: require("../../assets/images/plan/insurance-img3.png"),
            },
            {
              txt: "人保金融",
              imgUrl: require("../../assets/images/plan/insurance-img4.png"),
            },
            {
              txt: "中银消费金融",
              imgUrl: require("../../assets/images/plan/insurance-img5.png"),
            },
            {
              txt: "广发银行",
              imgUrl: require("../../assets/images/plan/insurance-img6.png"),
            },
            {
              txt: "招商银行",
              imgUrl: require("../../assets/images/plan/insurance-img7.png"),
            },
            {
              txt: "中国建设银行",
              imgUrl: require("../../assets/images/plan/insurance-img8.png"),
            },
          ],
        ],
        intro: "金融机构断卡及信贷业务，调用中码风险评估服务达2000万次",
      },
    ];

    let planShow = planList.find((item) => item.id == id) || {};

    return {
      planList,
      planShow,
    };
  },
});
</script>

<style lang="less" scoped>
.menu-box {
  width: 100%;
  height: 72px;
}
.plan-detail {
  padding: 50px 0;
  color: #142d70;
  .indent-2 {
    text-indent: 2em;
  }
  .plan-item {
    margin-bottom: 50px;
    .plan-item-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .title {
      font-weight: 600;
      margin-bottom: 20px;
    }
    .success-tip {
      font-weight: 600;
      text-align: center;
      background: #f6f7fa;
      border: 1px solid #e9e9f4;
      padding: 20px;
    }
  }
  .pain-wrap {
    display: flex;
    justify-content: space-between;
    .pain-item {
      background: #e9e9f4;
      color: rgba(88, 101, 156, 0.72);
      padding: 30px;
      width: 32%;
      .pain-item-title {
        font-size: 20px;
        color: #4259b9;
        font-weight: 600;
        margin-bottom: 20px;
        img {
          width: 24px;
          margin-right: 15px;
          vertical-align: text-bottom;
        }
      }
    }
  }
  .advan-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .advan-item {
      display: flex;
      align-items: center;
      background: #f6f7fa;
      border: 1px solid #e9e9f4;
      padding: 60px;
      position: relative;
      width: 49%;
      margin-bottom: 30px;
      color: #4259b9;
      font-weight: 600;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 228px;
        height: 9px;
        background: linear-gradient(
          135deg,
          rgba(0, 43, 190, 0.15) 0%,
          rgba(0, 19, 138, 0.52) 100%
        );
        border-radius: 0px 0px 68px 68px;
        top: 0;
        left: 0;
      }
      img {
        width: 50px;
        height: 50px;
        margin-right: 40px;
      }
    }
  }
  .info-wrap {
    .info-wrap-item {
      margin-bottom: 10px;
      .title {
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
  .success-img-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .success-img-item {
      width: 22%;
      text-align: center;
      font-weight: 600;
      img {
        width: 100%;
        margin-bottom: 15px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      }
    }
    .success-img-item-1 {
      width: 100%;
    }
    .success-img-item-2 {
      width: 48%;
    }
    .success-img-item-3 {
      width: 30%;
    }
    .success-img-item-6 {
      width: 15%;
    }
  }
}
</style>
<style lang="less" scoped>
.mobile {
  .plan-detail {
    padding: 20px;
    .plan-item {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      .pain-wrap {
        flex-wrap: wrap;
        & > .pain-item {
          width: 100%;
          margin-bottom: 10px;
          font-size: 13px;
          .pain-item-title{
            font-size: 18px;
          }
        }
      }
      .advan-wrap {
        font-size: 15px;
        .advan-item {
          width: 100%;
          margin-bottom: 10px;
          padding: 40px 20px;
        }
      }
      .success-img-wrap{
        flex-wrap: wrap;
        .success-img-item{
          width: 48%;
        }
      }
    }
  }
}
</style>